<template>
  <input v-bind="$attrs" class="file-input-hidden" type="file" @change="sendRequest()" ref="fileInput"/>
</template>

<script>
import {fetch} from "@/utils/request.js";

export default {
  name: "importFile",
  emits: ["response"],
  props: {
    query: {
      type: Object,
      default: () => {return {}}
    },
    url: {
      type: String
    }
  },
  methods: {
    upload() {
      this.$refs.fileInput.click()
    },
    sendRequest() {
      let formData = new FormData
      formData.set("file", this.$refs.fileInput.files[0])
      let query = Object.assign({}, this.query)
      for (let key in query) {
        formData.set(key, query[key])
      }
      return fetch("post", this.url, formData).then(res => {
        this.$emit('response', res)
        this.$refs.fileInput.value = ""
        return res
      })
    }
  }
}
</script>

<style>
.file-input-hidden {
  visibility: hidden;
  position: fixed;
  left: -100%;
  top: -100%;
}
</style>
<template>
  <div class="app-main-content">
    <card title="任务" :tabs="cat.issue_bstatus" v-model="listQuery.issue_status"
          body-class="overflow-hidden page-card-body" @change="search">
      <template #tools>
        <div>
          <a-button type="primary" @click="openModal" v-if="hasAddTaskP">
            <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
            任务项
          </a-button>
        </div>
        <div class="right">
          <div class="item">
            <div class="label">阶段</div>
            <a-select v-model="listQuery.phase_oid" @change="search">
              <a-select-option value="">全部</a-select-option>
              <a-select-option v-for="item in phaseList" :key="item.oid" :value="item.oid">
                {{ item.phase_name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <span class="label">排序</span>
            <a-select @change="search" class="sort-select" placeholder="请选择" style="width: 160px"
                      v-model="listQuery.orderby">
              <a-select-option :value="item.value" v-for="item in orderbyList" :key="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <span class="label">搜索</span>
            <a-input placeholder="请输入标题或者编码" style="width: 180px" v-model="listQuery.keywords">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>
          <div class="item">
            <a-button type="primary" :disabled="selectedRowKeys.length === 0" @click="openBatchChangeModal">批量调整
            </a-button>
          </div>
          <div class="item">
            <a-button @click="isFilter = !isFilter">筛选
              <a-icon class="filter" :class="{rotate:isFilter}" type="double-right" />
            </a-button>
          </div>
          <div class="item">
            <a-button class="search" @click="search">查询</a-button>
          </div>
          <div class="item">
            <a-dropdown>
              <img class="more" :src="$toUrl('more.png')">
              <a-menu slot="overlay" @click="selectActionMenu">
                <a-menu-item key="download">普通任务模板</a-menu-item>
                <a-menu-item v-if="importV2" key="downloadV2">多级任务模板</a-menu-item>
                <a-menu-item v-if="hasAddTaskP" key="import">普通任务导入</a-menu-item>
                <a-menu-item v-if="hasAddTaskP&&importV2" key="importV2">多级任务导入</a-menu-item>
                <a-menu-item key="export">普通任务导出</a-menu-item>
                <a-menu-item v-if="importV2" key="exportV2">多级任务导出</a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </template>

      <div class="filter-container" v-show="!isFilter">
        <div class="title">筛选条件</div>
        <div class="content">
          <div class="item">
            <div class="label">计划开始</div>
            <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                            v-model="listQuery.schedule_start_date" />
          </div>
          <div class="item">
            <div class="label">计划结束</div>
            <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                            v-model="listQuery.schedule_end_date" />
          </div>
          <div class="item">
            <div class="label">实际开始</div>
            <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                            v-model="listQuery.actual_start_date" />
          </div>
          <div class="item">
            <div class="label">实际结束</div>
            <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                            v-model="listQuery.actual_end_date" />
          </div>
          <div class="item">
            <div class="label">创建时间</div>
            <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                            v-model="listQuery.create_time" />
          </div>
          <div class="item">
            <div class="label">状态</div>
            <a-select v-model="listQuery.issue_status" @change="search">
              <a-select-option value="">全部</a-select-option>
              <a-select-option v-for="item in cat.issue_bstatus" :key="item.oid" :value="item.cat_code">
                {{ item.cat_name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <div class="label">优先级</div>
            <a-select v-model="listQuery.issue_level" @change="search">
              <a-select-option v-for="item in [{cat_code:'',cat_name:'全部'},...cat.issues_level]" :key="item.cat_code"
                               :value="item.cat_code">
                {{ item.cat_name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <div class="label">标签</div>
            <a-select style="width: 200px;height: 32px;overflow: hidden;" v-model="listQuery.issue_tags"
                      placeholder="请选择标签" mode="multiple" @change="search" :maxTagCount="1" allowClear>
              <a-select-option v-for="item in cat.issue_tags" :key="item.cat_code" :value="item.cat_name">
                {{ item.cat_name }}
              </a-select-option>
            </a-select>
          </div>

          <div class="item">
            <span class="label">负责人</span>
            <a-select
                mode="multiple"
                @change="search"
                class="sort-select"
                placeholder="请选择负责人"

                v-model="listQuery.issue_owners">
              <a-select-option v-for="item in accountList" :key="item.oid" :value="item.user_oid">{{
                  item.title ? item.name + '-' + item.title : item.name
                }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <span class="label">协同人</span>
            <a-select
                mode="multiple"
                @change="search"
                class="sort-select"
                placeholder="请选择协同人"

                v-model="listQuery.issue_assists">
              <a-select-option v-for="item in accountList" :key="item.oid" :value="item.user_oid">{{
                  item.title ? item.name + '-' + item.title : item.name
                }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </div>

      <div class="body-flex">
        <div class="body-left-table">
          <vxe-table
              ref="xTable"
              height="100%"
              :scroll-y="{enabled: true, gt: 50}"
              :scroll-x="{enabled: true, gt: 0}"
              :data="list"
              :show-overflow="false"
              :row-config="{
                  keyField: 'oid',
                  isHover: true,
                  isCurrent: true
                }"
              :checkbox-config="{
                  checkStrictly: true
                }"
              :tree-config="{
                  reserve: true,
                  transform: true,
                  expandAll: true,
                  parentField: 'parent_oid',
                  rowField: 'oid',
                  iconOpen: 'iconfont icon-xiangxia expand-icon',
                  iconClose: 'iconfont icon-xiangxia expand-icon open'
                }"
              @current-change="changeCurrentRow"
              :column-config="{resizable: true}"
          >
            <vxe-column type="checkbox" width="50">
              <template #header>
                <a-checkbox
                    @change="toggleAllCheck()"
                    :indeterminate="check.all_indeterminate"
                    :checked="check.all_indeterminate ? undefined : check.all_checked"
                />
              </template>
              <template #checkbox="{ row }">
                <a-checkbox
                    @change="toggleCheck(row)"
                    :checked="selectedRowKeys.includes(row.oid)"
                />
              </template>
            </vxe-column>
            <vxe-column field="seqid" title="ID" width="80px">
              <template v-slot="{row}">
                <span style="white-space: nowrap">{{ row.seqid }}</span>
              </template>
            </vxe-column>
            <vxe-column title="任务编码" :resizable="true" width="150px">
              <template v-slot="{row}">
                <a-tooltip>
                  <template slot="title">{{ row.issue_code || '暂无' }}</template>
                  <edp-input
                      style="--width: 100%"
                      v-model="row.issue_code"
                      :db="dbs.prjIssue"
                      field-name="issue_code"
                      :oid="row.oid"
                      :prj-oid="row.prjoid"
                      @change="() => {
                          $refs.issueDetail.getDetail()
                          getList()
                        }"
                  >
                  </edp-input>
                </a-tooltip>
              </template>
            </vxe-column>
            <vxe-column title="标题" :resizable="true" :width="(maxDeep * 25 + 250) + 'px'" tree-node>
              <template v-slot="{row}">
                <div class="edp-title">
                  <img v-if="row.is_later === '1' || row.issue_status === '10'" :src="$toUrl('task_red.png')" alt="">
                  <img v-else-if="row.issue_status === '0'" :src="$toUrl('task_blue.png')" alt="">
                  <img v-else-if="row.issue_status === '1'" :src="$toUrl('task_yellow.png')" alt="">
                  <img v-else-if="row.issue_status === '100'" :src="$toUrl('task_green.png')" alt="">
                  <a-tooltip>
                    <template slot="title">{{ row.issue_name }}</template>
                    <span style="margin-right: 5px;">{{ row.issue_name }}</span>
                  </a-tooltip>
                  <a-tooltip v-if="hasAddTaskP">
                    <template slot="title">新增子任务</template>
                    <img style="cursor: pointer;height: 16px;" :src="$toUrl('mini-add.png')" class="hidden" alt=""
                         @click="openChildrenTaskModal(row)">
                  </a-tooltip>
                  <a-tooltip>
                    <template slot="title">设置前置任务项</template>
                    <i class="iconfont icon-guanlian hidden" @click="openRelevancyWorkModal(row)"></i>
                  </a-tooltip>
                </div>
              </template>
            </vxe-column>
            <vxe-column title="前置任务" width="100px">
              <template v-slot="{row}">
                <a-tooltip>
                  <template slot="title">{{ row.pretasks || '暂无' }}</template>
                  <edp-input
                      style="--width: 100%"
                      v-model="row.pretasks"
                      :db="dbs.prjIssue"
                      field-name="pretasks"
                      :oid="row.oid"
                      :prj-oid="row.prjoid"
                      @change="$refs.issueDetail.getDetail()"
                  >
                  </edp-input>
                </a-tooltip>
              </template>
            </vxe-column>
            <vxe-column title="优先级" width="85px">
              <template v-slot="{row}">
                    <span
                        class="edp-tag"
                        :class="{
                        green: row.issue_level === '100',
                        blue: row.issue_level === '200',
                        yellow: row.issue_level === '300',
                        red: row.issue_level === '400',
                      }"
                        v-if="row.issue_level">{{ row.issue_level_name }}</span>
              </template>
            </vxe-column>
            <vxe-column title="进度" width="80px">
              <template v-slot="{row}">
                <div @click.stop class="stop">
                  <edp-input
                      v-if="$hasP('T_UPDATEALL') && (!row.children)"
                      size="mini"
                      number
                      style="--width: 60px"
                      :db="dbs.prjIssue"
                      field-name="complete_percent"
                      v-model="row.complete_percent"
                      :oid="row.oid"
                      :prj-oid="row.prjoid"
                      :precision="0"
                  >
                    <span>{{ row.complete_percent ? `${row.complete_percent}%` : `` }}</span>
                  </edp-input>
                  <span v-else>{{ row.complete_percent ? `${row.complete_percent}%` : `` }}</span>
                </div>
              </template>
            </vxe-column>
            <vxe-column title="状态" width="85px" field="issue_status">
              <template v-slot="{row}">
                    <span
                        class="edp-status"
                        :class="{
                        blue: row.issue_status === '0',
                        yellow: row.issue_status === '1',
                        green: row.issue_status === '100',
                      }"
                        v-if="row.issue_status"
                    >
                      {{ row.issue_status_name }}
                    </span>
              </template>
            </vxe-column>
            <vxe-column title="负责人" width="160px">
              <template v-slot="{row}">
                <div class="edp-owners">
                  <div v-for="item in row.owners" :key="item.oid">
                    <a-avatar :size="16" :src="item.pic" />
                    <span>{{ item.title ? item.name + '-' + item.title : item.name }}</span>
                  </div>
                </div>
              </template>
            </vxe-column>
            <vxe-column title="协同人" width="170px">
              <template v-slot="{row}">
                <div class="edp-owners">
                  <div v-for="item in row.assists" :key="item.oid">
                    <a-avatar :size="16" :src="item.pic" style="margin-right: 5px;" />
                    <span>{{ item.title ? item.name + '-' + item.title : item.name }}</span>
                  </div>
                </div>
              </template>
            </vxe-column>
            <vxe-column title="附件" width="110px">
              <template v-slot="{row}">{{ row.has_file === '1' ? '有' : '空'}}</template>
            </vxe-column>
            <vxe-column title="计划开始" field="schedule_start_date" width="110px"></vxe-column>
            <vxe-column title="实际开始" field="actual_start_date" width="110px"></vxe-column>
            <vxe-column title="计划结束" field="schedule_end_date" width="110px"></vxe-column>
            <vxe-column title="实际结束" field="actual_end_date" width="110px">
            </vxe-column>
            <vxe-column title="创建时间" field="create_time" width="110px">
            </vxe-column>
          </vxe-table>
        </div>
        <issue-detail ref="issueDetail" :oid="detailOid" @reload="search" @render="isRender = true"></issue-detail>
      </div>
    </card>
    <relevancy-work-modal ref="relevancyWorkModal" @reload="reload" />
    <create-task-modal @reload="reload" ref="taskModal"></create-task-modal>
    <batch-change-modal @reload="getList" ref="batchChange"></batch-change-modal>
    <import-file url="/prj/issue/import" :query="{prjoid: $route.query.prj_oid}" ref="import"
                 @response="importSuccess"></import-file>
    <import-file url="/prj/issue/importV2" :query="{prjoid: $route.query.prj_oid}" ref="importV2"
                 @response="importSuccess2"></import-file>
  </div>
</template>

<script>
import CreateTaskModal from "@/components/createTaskModal.vue";
import listMixin from "@/utils/mixins/listMixin.js";
import catMixin from "@/utils/mixins/catMixin";
import { fetch } from "@/utils/request.js";
import IssueDetail from "@/components/issueDetail.vue";
import { ChildData2Vxe, getTemplate, queryChildren } from "@/utils/helpFunc";
import importFile from "@/components/importFile.vue";
import EdpInput from "@/components/e-form/edp-input.vue";
import { FieldUpdateDb } from "@/utils/FieldUpdate";
import BatchChangeModal from "@/components/batchChangeModal.vue";
import RelevancyWorkModal from "@/components/relevancyWorkModal.vue";
import lodash from "lodash";

export default {
  components: { RelevancyWorkModal, BatchChangeModal, EdpInput, IssueDetail, CreateTaskModal, importFile },
  mixins: [listMixin, catMixin],
  data() {
    return {
      isFilter: false,
      isRender: false,
      comment: "",
      detailOid: "",
      dbs: {
        prjIssue: new FieldUpdateDb("prj", "prj_issue"),
      },
      cat: {
        issues_level: [],
        issue_bstatus: [],
        import_issue: [],
        issue_tags: []
      },
      listMixinOptions: {
        usePage: false,
        mockUrl: "http://127.0.0.1:4523/m1/2234408-0-default/prj/issue/retrieve/toplevel/byprj",
        url: "/prj/issue/get"
      },
      page: {
        page_size: 10,
        page_index: 1,
        total: 0,
      },
      listQuery: {
        prjoid: this.$route.query.prj_oid,
        keywords: "",
        orderby: "a.create_time asc",
        issue_status: "",
        issue_owner: "",
        issue_owners: [],
        issue_type: "T",
        phase_oid: "",
        issue_level: '',
        issue_tags: [],
        issue_assists: [],
        schedule_start_date: [],
        schedule_end_date: [],
        actual_start_date: [],
        actual_end_date: [],
        create_time: [],
      },
      orderbyList: [
        { value: 'a.create_time asc', name: '创建时间正序' },
        { value: 'a.create_time desc', name: '创建时间倒序' },
        { value: 'a.issue_level asc', name: '优先级正序' },
        { value: 'a.issue_level desc', name: '优先级倒序' },
        { value: 'a.actual_start_date asc', name: '实际开始时间正序' },
        { value: 'a.actual_start_date desc', name: '实际开始时间倒序' },
        { value: 'a.schedule_start_date asc,a.schedule_end_date asc', name: '计划开始时间正序' },
        { value: 'a.schedule_start_date desc', name: '计划开始时间倒序' },
        { value: 'a.schedule_end_date asc', name: '计划结束时间正序' },
        { value: 'a.schedule_end_date desc', name: '计划结束时间倒序' },
        { value: 'a.issue_status asc', name: '状态升序' },
        { value: 'a.issue_status desc', name: '状态倒序' },
      ],
      selectedRowKeys: [],
      commentList: [],
      accountList: [],
      expandKeys: [],
      phaseList: [],
      createIdOrder: false,
      maxDeep: 1,
    }
  },
  computed: {
    check() {
      let select = this.checkedList;
      return {
        all_indeterminate: select.length !== 0 && select.length !== this.list.length,
        all_checked: select.length === this.list.length
      }
    },
    checkedList() {
      return this.list.filter(item => {
        return this.selectedRowKeys.includes(item.oid)
      })
    },
    hasAddTaskP() {
      return this.$hasP('T_CREATE')
    },
    importV2() {
      return this.cat.import_issue.filter(item => item.cat_code === "001")[0]?.field_value === '1'
    }
  },
  watch: {
    list: {
      deep: true,
      handler(val) {
        queryChildren(val)
      }
    },
    selectedRowKeys(val, pre) {
      const result = [...val]
      const findRow = (oid, list = this.list) => {
        const find = list.find(item => item.oid === oid)
        if (find) {
          return find
        } else {
          for (let item of list) {
            if (Array.isArray(item.children)) {
              let result = findRow(oid, item.children)
              if (result) {
                return result
              }
            }
          }
          return false
        }
      }
      let diff = val.filter(item => {
        return !pre.includes(item)
      }).map(item => findRow(item)).filter(item => item !== false)
      const deepSetChecked = (list) => {
        for (let item of list) {
          if (!result.includes(item.oid)) {
            result.push(item.oid)
          }
          if (Array.isArray(item.children)) {
            deepSetChecked(item.children)
          }
        }
      }
      deepSetChecked(diff)
      if (JSON.stringify(this.selectedRowKeys) !== JSON.stringify(result)) {
        this.selectedRowKeys = result
      }
    }
  },
  created() {
    this.getAccount()
    this.getPhaseList()
  },
  mounted() {
    if (this.$route.query.oid) {
      this.detailOid = this.$route.query.oid
    }
  },
  methods: {
    toggleCheck(row) {
      let index = this.selectedRowKeys.indexOf(row.oid)
      console.log(index)
      if (index !== -1) {
        this.selectedRowKeys.splice(index, 1)
        this.$forceUpdate()
      } else {
        let result = []
        let deepSetChecked = (parent_oid) => {
          let filter = this.list.filter(item => {
            return item.parent_oid === parent_oid && !this.selectedRowKeys.includes(item.oid)
          })
          filter.forEach(item => {
            deepSetChecked(item.oid)
            result.push(item.oid)
          })
        }
        deepSetChecked(row.oid)
        this.selectedRowKeys.push(row.oid, ...result)
      }
    },
    toggleAllCheck() {
      if (this.check.all_indeterminate || !this.check.all_checked) {
        this.selectedRowKeys = this.list.map(item => item.oid)
      } else if (this.check.all_checked) {
        this.selectedRowKeys = []
      }
    },
    changeCurrentRow({ row }) {
      this.detailOid = row.oid
    },
    search() {
      this.page.page_index = 1
      this.getList()
    },
    getList() {
      const oldQuery = {
        prjoid: this.$route.query.prj_oid,
        keywords: "",
        orderby: "a.create_time asc",
        issue_status: "",
        issue_owner: "",
        issue_owners: [],
        issue_type: "T",
        phase_oid: "",
        issue_level: '',
        issue_assists: [],
        issue_tags: [],
        schedule_start_date: [],
        schedule_end_date: [],
        actual_start_date: [],
        actual_end_date: [],
        create_time: [],
      }
      delete this.listQuery.select_tag;
      const isEqual = lodash.isEqual(this.listQuery, oldQuery)
      if (!isEqual) {
        this.listQuery.select_tag = true
      }
      fetch("post", '/prj/issue/get', this.getListQuery(JSON.parse(JSON.stringify(this.listQuery)))).then(res => {
        if (res.status === 0) {
          this.detailOid = res.data[0]?.oid
          let vxeData = ChildData2Vxe(res.data.map(e => {
            return {
              ...e,
              children: e.end_flag === '1' ? e.children : undefined
            }
          }))
          let data = vxeData.data
          this.maxDeep = vxeData.maxDeep
          this.list = data
          if (!this.detailOid && data[0]?.oid) {
            this.detailOid = data[0]?.oid
          }
        }
      })
    },
    getListQuery(obj) {
      let query = {
        ...obj,
        issue_owners: obj.issue_owners.join(","),
        issue_assists: obj.issue_assists.join(","),
        issue_tags: obj.issue_tags.join(",")
      }
      if (query.schedule_start_date[0]) {
        query.schedule_start_date_begin = query.schedule_start_date[0]
        query.schedule_start_date_end = query.schedule_start_date[1]
      }
      delete query.schedule_start_date
      if (query.schedule_end_date[0]) {
        query.schedule_end_date_begin = query.schedule_end_date[0]
        query.schedule_end_date_end = query.schedule_end_date[1]
      }
      delete query.schedule_end_date
      if (query.actual_start_date[0]) {
        query.actual_start_date_begin = query.actual_start_date[0]
        query.actual_start_date_end = query.actual_start_date[1]
      }
      delete query.actual_start_date
      if (query.actual_end_date[0]) {
        query.actual_end_date_begin = query.actual_end_date[0]
        query.actual_end_date_end = query.actual_end_date[1]
      }
      delete query.actual_end_date
      if (query.create_time[0]) {
        query.create_time_begin = query.create_time[0]
        query.create_time_end = query.create_time[1]
      }
      delete query.create_time
      return query
    },
    openRelevancyWorkModal(row) {
      this.$refs.relevancyWorkModal.prjoid = row.prjoid
      this.$refs.relevancyWorkModal.open(row)
    },
    paginationChange(pagination, filter, sort) {
      this.page.page_index = pagination.current
      this.page.page_size = pagination.pageSize
      this.setSortQuery(sort)
    },
    getPhaseList() {
      fetch('post', '/prj/phase/retrieve/byprj', {
        prjoid: this.$route.query.prj_oid
      }).then((res) => {
        if (res.status === 0) {
          this.phaseList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    openBatchChangeModal() {
      this.$refs.batchChange.open(this.selectedRowKeys.join(","))
      this.$refs.batchChange.form.prjoid = this.$route.query.prj_oid
      this.$refs.batchChange.onChangePrj()
      this.$refs.batchChange.getPhaseList()
    },

    reload() {
      this.search()
      this.$refs.issueDetail.reloadMoneyModalIssueList()
    },
    getAccount() {
      fetch("post", "/prj/userlist/list", { prjoid: this.$route.query.prj_oid }).then(res => {
        if (res.status === 0) {
          this.accountList = res.data
        }
      })
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.detailOid = record.oid
          }
        }
      }
    },
    async getChildren(record) {
      let url = window.mock ? "http://127.0.0.1:4523/m1/2234408-0-default/prj/issue/retrieve/children?apifoxApiId=59781388" : "/prj/issue/retrieve/children"
      await fetch("post", url, {
        oid: record.oid
      }).then(async res => {
        for (let i = 0; i < res.data.length; i++) {
          record.children.push({
            ...res.data[i],
            children: this.expandKeys.includes(res.data[i].oid) ? await this.getChildren() : []
          })
        }
      })
    },
    async expand(expanded, record) {
      if (expanded && record.children.length === 0) {
        await this.getChildren(record)
      }
    },
    async listFilter(data) {
      // for (let i=0; i< data.length; i++) {
      //   data[i].children = []
      //   if (this.expandKeys.includes(data[i].oid)) {
      //     await this.getChildren(data[i])
      //   }
      // }
      return data
    },
    openModal() {
      this.$refs.taskModal.open({
        prjoid: this.$route.query.prj_oid
      })
      this.$refs.taskModal.disableProject = true
    },
    selectActionMenu({ key }) {
      if (key === "import") {
        this.$refs.import.upload()
      } else if (key === 'importV2') {
        this.$refs.importV2.upload()
      } else if (key === "export") {
        // console.log(this.list.map(item => item.oid).join(','));
        if (this.list.length > 0) {
          window.open(this.$toUrl(`/prj/issue/export?issueoids=${this.list.map(item => item.oid).join(',')}`, true, "api"))
        } else {
          this.$message.error('暂无任务')
        }
      } else if (key === "exportV2") {
        if (this.selectedRowKeys.length > 0) {
          console.log(this.selectedRowKeys.join(','))
          window.open(this.$toUrl(`/prj/issue/exportV2?issueoids=${this.selectedRowKeys.join(',')}`, true, "api"))
        } else {
          this.$message.error('请勾选任务')
        }
      } else if (key === "download") {
        getTemplate('/attachment/model', '任务项导入模板.xlsx')
      } else if (key === "downloadV2") {
        getTemplate('/attachment/model', '多级任务模板.xlsx')
      }
    },
    // getDetailOid(oid){
    //   if (!this.detailOid) {
    //     this.detailOid = oid
    //   }
    // },
    beforeGetList() {
      if (!this.detailOid) {
        this.detailOid = this.list?.[0]?.oid
      }
    },
    changeSelected(list) {
      this.selectedRowKeys = list
    },
    setSortQuery(sort) {
      this.createIdOrder = sort.order
      console.log(sort);
      if (sort.order === "descend") {
        this.listQuery.orderby = sort.field + ' desc'
      } else if (sort.order === "ascend") {
        this.listQuery.orderby = sort.field + ' asc'
      } else {
        this.listQuery.orderby = ""
      }
    },
    openChildrenTaskModal(row) {
      this.$refs.taskModal.open({
        prjoid: row.prjoid,
        route_level: row.route_level - 0 + 1,
        route_map: row.route_map + '/' + row.oid,
        phase_oid: row.phase_oid
      })
    },
    importSuccess(res) {
      if (res.status === 0) {
        this.$message.success("导入成功")
        this.getList()
      } else {
        this.$message.error(res.message)
      }
    },
    importSuccess2(res) {
      if (res.status === 0) {
        this.$message.success("正在导入中，请30分钟后查看！")
        // this.getPrjPhaseList()
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.filter-container {
  margin-bottom: 12px;

  .title {
    font-size: 18px;
    color: #000000;
    margin-bottom: 12px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 12px;

      .label {
        margin-right: 10px;
      }

      .ant-calendar-picker {
        width: 300px;
      }

      .ant-select {
        width: 180px;
      }
    }
  }
}

.filter {
  transition: all .3s;
  transform: rotate(-90deg);

  &.rotate {
    transform: rotate(90deg)
  }
}

.app-main-content {
  /deep/ .vxe-table--render-default {
    .vxe-table--header-wrapper {
      background-color: #f8f8f8;

      .vxe-body--x-space {
        background-color: #f8f8f8;
      }

      .vxe-header--column {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .vxe-body--row {
      &.row--current {
        background-color: initial;
      }

      &.row--hover {
        background-color: #fff4e6;
      }

      .vxe-tree--btn-wrapper {
        display: flex;
      }
    }
  }

  /deep/ .page-card-body {
    padding: 10px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    // 表格上方的操作栏
    > .body-action {
      display: flex;
      border-bottom: 1px solid #eee;

      .right {
        align-items: center;
        display: flex;
        margin-left: auto;
        margin-bottom: 10px;

        .item {
          margin-left: 20px;

          .label {
            margin-right: 10px;
          }
        }
      }
    }

    // 表格和右侧信息的父元素 选择器
    > .body-flex {
      width: 100%;
      flex: 1;
      display: flex;
      overflow: hidden;
      background-color: #F8F8F8;
      // 左侧表格
      > .body-left-table {
        flex: 1;
        height: 100%;
        overflow-y: auto;
        overflow-x: auto;
        background: #FFF;
      }
    }
  }
}

/deep/ .ant-table-body {
  .ant-table-tbody {
    tr {
      td {
        white-space: nowrap;
      }
    }
  }
}
</style>

<template>
  <div class="body-right-info edp-right-detail" v-if="hasDetail">
    <div class="info-scroll-content">
      <div class="content-head">
        <div class="content-head-id">
          <template v-if="detail.issue_type === 'T'">
            <img class="icon" :src="$toUrl('task_blue.png')" alt="">
          </template>
          <template v-else-if="detail.issue_type === 'M'">
            <img class="pic" :src="$toUrl('milepost_blue.png')">
          </template>
          <span class="id">#{{ detail.seqid }}</span>
        </div>
        <div v-if="hasDeleteP" class="content-head-action" style="margin-left: auto;font-size: 14px">
          <div class="action-item" @click="deleteDetail()">删除</div>
        </div>
      </div>
      <div class="title">
        <edp-input
            v-if="hasEditP"
            size="big"
            style="width: 100%;"
            v-model="detail.issue_name"
            :db="dbs.prjIssue"
            field-name="issue_name"
            :oid="detail.oid"
            :prj-oid="detail.prjoid"
            @change="$emit('reload')"
        ></edp-input>
        <template v-else>{{ detail.issue_name }}</template>
      </div>
      <edp-checkbox-group
          field-name="issue_tag"
          :db="dbs.prjIssue"
          :prj-oid="detail.prjoid"
          :oid="detail.oid"
          v-model="detail.issue_tag"
          :options="cat.issue_tags.map(item => item.cat_name)"
      ></edp-checkbox-group>
      <div class="scroll-content-status">
        <div class="status-item">
          <img v-if="detail.owners[0]?.pic" class="round" :src="detail.owners[0].pic" alt="">
          <span v-else class="round"></span>
          <div class="status-item-right">
            <div class="status-title">
              <edp-select
                  v-if="hasEditP"
                  size="mini"
                  v-model="detail.issue_owner"
                  :db="dbs.prjIssue"
                  field-name="issue_owner"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  account="prj"
                  showSearch
                  @change="() => {
                  $emit('reload')
                  getDetail()
                }"
              ></edp-select>
              <div style="padding-bottom: 3px" v-else>{{ detail.owners?.[0]?.name }}</div>
            </div>
            <div class="status-sub-text">负责人</div>
          </div>
        </div>
        <div class="status-item">
          <img class="round" v-if="detail.issue_status === '0'" :src="$toUrl('task_status_blue.png')" alt="">
          <img class="round" v-else-if="detail.issue_status === '1'" :src="$toUrl('task_status_yellow.png')" alt="">
          <img class="round" v-else-if="detail.issue_status === '10'" :src="$toUrl('task_suspend.png')" alt="">
          <img class="round" v-else-if="detail.issue_status === '100'" :src="$toUrl('task_status_green.png')" alt="">
          <div class="status-item-right">
            <div class="status-title">
              <edp-select
                  v-if="hasEditP"
                  ref="issue_status"
                  size="mini"
                  v-model="detail.issue_status"
                  :db="dbs.prjIssue"
                  field-name="issue_status"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  :options="cat.issue_bstatus"
                  options-type="cat"
                  @change="() => {
                  $emit('reload')
                  getDetail()
                }"
              >
              </edp-select>
              <template v-else>{{ getCatNameByCode(detail.issue_status, 'issue_bstatus') }}</template>
            </div>
            <div class="status-sub-text">状态</div>
          </div>
        </div>
        <div class="status-item">
          <img class="round" :src="`${$appsettings.imgPrefix}level.png`" alt="">
          <div class="status-item-right">
            <div class="status-title">
              <edp-select
                  v-if="hasEditP"
                  size="mini"
                  v-model="detail.issue_level"
                  :db="dbs.prjIssue"
                  field-name="issue_level"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  :options="issues_level_list"
                  options-type="cat"
                  @change="$emit('reload')"
              >
              </edp-select>
              <div style="padding-bottom: 3px" v-else>{{ getCatNameByCode(detail.issue_level, 'issues_level') }}</div>
            </div>
            <div class="status-sub-text">优先级</div>
          </div>
        </div>
        <div class="status-item">
          <img class="round" :src="`${$appsettings.imgPrefix}tophase.png`" alt="">
          <div class="status-item-right">
            <div class="status-title">
              <edp-select
                  v-if="hasEditP"
                  size="mini"
                  v-model="detail.phase_oid"
                  :db="dbs.prjIssue"
                  field-name="phase_oid"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  @change="$emit('reload')"
                  :options="stageList"
              >
              </edp-select>
              <div style="padding-bottom: 3px" v-else>{{ detail.phase_name }}</div>
            </div>
            <div class="status-sub-text">所属阶段</div>
          </div>
        </div>
        <div class="status-item">
          <img class="round" :src="$toUrl('right_detail_project.png')">
          <div class="status-item-right">
            <div style="padding-bottom: 3px; line-height: 1.2" class="status-title">{{ detail.project_name }}</div>
            <div class="status-sub-text">所属项目</div>
          </div>
        </div>
        <div class="status-item">
          <img class="round" :src="$toUrl('right_detail_project_code.png')">
          <div class="status-item-right">
            <div style="padding-bottom: 3px" class="status-title">{{ detail.project_code }}</div>
            <div class="status-sub-text">项目编号</div>
          </div>
        </div>
      </div>
      <div class="title" style="font-size: 16px;font-weight: 500">
        描述
      </div>
      <edp-textarea
          v-if="hasEditP"
          class="description"
          v-model="detail.issue_desc"
          :db="dbs.prjIssue"
          field-name="issue_desc"
          :oid="detail.oid"
          :prj-oid="detail.prjoid"
          :min-rows="2"
          @change="$emit('reload')"
      ></edp-textarea>
      <div v-else>{{ detail.issue_desc }}</div>
      <div class="content-group-info">
        <div class="group-info-title">属性</div>
        <div class="group-info-list">
          <div class="info-item">
            <div class="info-key">进度</div>
            <div class="info-value">
              <edp-input
                  v-if="hasEditP"
                  :db="dbs.prjIssue"
                  field-name="complete_percent"
                  v-model="detail.complete_percent"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  number
                  :precision="0"
                  :max="100"
                  @change="() => {
                  $emit('reload')
                  getDetail()
                }"
              >
                <span>{{ detail.complete_percent }}%</span>
              </edp-input>
              <template v-else>{{ detail.complete_percent }}%</template>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">任务编码</div>
            <div class="info-value">
              <edp-input
                  v-if="hasEditP"

                  v-model="detail.issue_code"
                  :db="dbs.prjIssue"
                  field-name="issue_code"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
              >
              </edp-input>
              <template v-else>{{ detail.issue_code }}</template>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">计划开始</div>
            <div class="info-value">
              <edp-date-picker
                  v-if="hasEditP"
                  v-model="detail.schedule_start_date"
                  :db="dbs.prjIssue"
                  field-name="schedule_start_date"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  @change="() => {
                  $emit('reload')
                  changeScheduleStartDate()
                }"
                  @openChange="recordStartDate"
              >
              </edp-date-picker>
              <template v-else>{{ detail.schedule_start_date }}</template>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">计划结束</div>
            <div class="info-value">
              <edp-date-picker
                  ref="schedule_end_date"
                  v-if="hasEditP"
                  v-model="detail.schedule_end_date"
                  :db="dbs.prjIssue"
                  field-name="schedule_end_date"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  @change="$emit('reload')"
              >
              </edp-date-picker>
              <template v-else>{{ detail.schedule_end_date }}</template>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">实际开始</div>
            <div class="info-value">
              <edp-date-picker
                  v-if="hasEditP"
                  v-model="detail.actual_start_date"
                  :db="dbs.prjIssue"
                  field-name="actual_start_date"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  @change="() => {
                    $emit('reload')
                    this.getDetail()
                  }"
              >
              </edp-date-picker>
              <template v-else>{{ detail.schedule_start_date }}</template>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">实际结束</div>
            <div class="info-value">
              <edp-date-picker
                  v-if="hasEditP"
                  v-model="detail.actual_end_date"
                  :db="dbs.prjIssue"
                  field-name="actual_end_date"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  @change="() => {
                  if (detail.issue_status !== '100') {
                    this.$refs.issue_status.setValue('100')
                  } else {
                    $emit('reload')
                  }
                }"
              >
              </edp-date-picker>
              <template v-else>{{ detail.actual_end_date }}</template>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">关联产品</div>
            <div class="info-value">
              <edp-select
                  v-if="hasEditP"
                  style="--width: 200px"
                  v-model="detail.product_oid"
                  :db="dbs.prjIssue"
                  field-name="product_oid"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  product="prj"
              ></edp-select>
              <template v-else>{{ detail.product_name }}</template>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">创建日期</div>
            <div class="info-value">{{ detail.create_time }}</div>
          </div>
          <div class="info-item">
            <div class="info-key">创建人</div>
            <div class="info-value">
              {{ detail.creator_title ? detail.creator_name + '-' + detail.creator_title : detail.creator_name }}
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">协同人</div>
            <div class="info-value">
              <edp-select
                  class="synergies"
                  v-if="hasEditP"
                  :db="dbs.prjIssue"
                  :oid="detail.oid"
                  v-model="detail.issue_assist"
                  field-name="issue_assist"
                  :prj-oid="detail.prjoid"
                  account="prj"
                  split-str="/"
                  multiple
                  :maxTagCount="100"
                  @change="$emit('reload')"
              ></edp-select>
              <template v-else>
                {{ detail.assists.map(item => item.name).join(',') }}
              </template>
            </div>
          </div>
        </div>
        <div class="line"></div>
      </div>

      <!-- 前置任务 -->
      <div class="content-group-info">
        <div class="head">
          <div class="text">前置任务</div>
          <div class="right-action" @click="openRelevancyWorkModal(detail)">
            <i class="iconfont icon-guanlian hidden" style="margin-right: 5px;"></i>
            设置前置任务项
          </div>
        </div>
        <div class="tag-record-list">
          <template v-if="detail.pretask_list.length">
            <div class="tag-record-item" v-for="item in detail.pretask_list" :key="item.oid" @click="openTask(item)">
              <div class="edp-tag" v-if="item.owners">
                {{ item.owners.map(i => i.title ? i.name + '-' + i.title : i.name).join(',') }}
              </div>
              <div>编码：{{ item.issue_code }}</div>
              <div class="name">{{ item.issue_name }}</div>
              <div class="edp-status"
                   :class="{
                    blue: item.issue_status === '0',
                    yellow: item.issue_status === '1',
                    green: item.issue_status === '100',
                    gray: item.issue_status === '10',
                  }">
                {{ item.issue_status_name }}
              </div>
              <div class="close" @click.stop="deleteTask(item)">
                <a-icon type="close" />
              </div>
            </div>
          </template>
          <edp-empty v-else />
        </div>
      </div>
      <div class="content-group-info">
        <div class="group-info-title">后置任务</div>
        <div class="tag-record-list">
          <template v-if="detail.todotask_list.length">
            <div class="tag-record-item" v-for="item in detail.todotask_list" :key="item.oid" @click="openTask(item)">
              <div class="edp-tag" v-if="item.owners">
                {{ item.owners.map(i => i.title ? i.name + '-' + i.title : i.name).join(',') }}
              </div>
              <div>编码：{{ item.issue_code }}</div>
              <div class="name">{{ item.issue_name }}</div>
              <div class="edp-status"
                   :class="{
                    blue: item.issue_status === '0',
                    yellow: item.issue_status === '1',
                    green: item.issue_status === '100',
                    gray: item.issue_status === '10',
                  }">
                {{ item.issue_status_name }}
              </div>
            </div>
          </template>
          <edp-empty v-else />
        </div>
      </div>

      <div class="content-group-info">
        <div class="group-info-title">财务信息</div>
        <div class="group-info-list">
          <div class="info-item">
            <div class="info-key">计划收入</div>
            <div class="info-value money">
              <span>CNY</span>
              <edp-input
                  v-if="hasEditP"
                  v-model="detail.schedule_income"
                  number
                  :db="dbs.prjIssue"
                  field-name="schedule_income"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  @change="$emit('reload')"
              >
              </edp-input>
              <template v-else>{{ detail.schedule_income }}</template>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">计划支出</div>
            <div class="info-value money">
              <span>CNY</span>
              <edp-input
                  v-if="hasEditP"
                  v-model="detail.schedule_outcome"
                  number
                  :db="dbs.prjIssue"
                  field-name="schedule_outcome"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  @change="$emit('reload')"
              >
              </edp-input>
              <template v-else>{{ detail.schedule_outcome }}</template>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">实际收入</div>
            <div class="info-value money">
              <span>CNY</span>
              <span style="font-size: 14px;">
                {{ detail.actual_income | numberFormat }}
              </span>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">实际支出</div>
            <div class="info-value money">
              <span>CNY</span>
              <span>{{ detail.actual_outcome | numberFormat }}</span>
            </div>
          </div>
          <issue-money-log
              style="margin-top: 20px;"
              title="财务明细"
              :disabled="!hasEditP"
              ref="moneyLog"
              :mini="true"
              @reload="getDetail"
              :oid="detail.oid"
              :prj-oid="detail.prjoid"
              :name="detail.issue_name"
              padding-x="0">
          </issue-money-log>
        </div>
      </div>
      <div class="content-group-info">
        <div class="group-info-title">工时信息</div>
        <div class="group-info-list">
          <div class="info-item">
            <div class="info-key">预估工时</div>
            <div class="info-value money">
              <edp-input
                  style="width: 60px;margin-left: -1px;"
                  v-if="hasEditP"
                  v-model="detail.schedule_worktime"
                  :db="dbs.prjIssue"
                  field-name="schedule_worktime"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  @change="() => {
                  $emit('reload')
                  getDetail()
                  }"
              >
              </edp-input>
              <div style="min-width: 60px" v-else>{{ detail.schedule_worktime }}</div>
              <span>小时</span>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">已登记工时</div>
            <div class="info-value money">
              <div style="min-width: 60px">{{ detail.actual_worktime }}</div>
              <div>小时</div>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">剩余工时</div>
            <div class="info-value money">
              <div style="min-width: 60px">{{ detail.worktime_left }}</div>
              <div>小时</div>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">预估偏差</div>
            <div class="info-value money">
              <div style="min-width: 60px">{{ detail.worktime_deviation }}</div>
              <div>小时</div>
            </div>
          </div>

        </div>
        <div class="line"></div>
      </div>
      <div class="content-group-info">
        <div class="group-info-list">
          <issue-work-time-log ref="workTimeLog" :list="detail.worktimes"
                               :prj-oid="detail.prjoid" :issue-oid="detail.oid" @reload="getDetail"
                               padding-x="0"></issue-work-time-log>
          <div class="line"></div>
        </div>
      </div>
      <div class="content-group-info">
        <div class="group-info-list">
          <attachment-upload title="附件上传" :disabled="!hasEditP" :prj-oid="detail.prjoid" :source-oid="oid"
                             source-type="3" @change="$emit('reload')">
          </attachment-upload>
          <a-tabs class="container-tabs" default-active-key="1">
            <a-tab-pane key="1" tab="评论">
              <div class="tabs-content">
                <comment-list ref="commentList" :prjoid="detail.prjoid" :issueoid="detail.oid"></comment-list>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="动态">
              <div class="tabs-content">
                <bizlog ref="bizlog" :source-oid="oid"></bizlog>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>

      </div>
    </div>
    <div class="right-info-footer">
      <div class="input-group">
        <input class="input" placeholder="评论" v-model="comment" />
        <div class="btn" @click="sendComment">
          发送
        </div>
      </div>
    </div>
    <relevancy-work-modal ref="relevancyWorkModal" @reload="() => {
      this.$emit('reload')
      this.getDetail()
    }" />
    <task-modal ref="taskModal" @reload="getDetail"></task-modal>
  </div>
</template>

<script>
import CommentList from "@/components/commentList.vue";
import Bizlog from "@/components/bizlog.vue";
import attachmentUpload from "@/components/attachmentUpload.vue";
import { fetch } from "@/utils/request.js";
import catMixin from "@/utils/mixins/catMixin.js";
import IssueMoneyLog from "@/components/issueMoneyLog.vue";
import EdpInput from "@/components/e-form/edp-input.vue";
import { FieldUpdateDb } from "@/utils/FieldUpdate.js";
import EdpSelect from "@/components/e-form/edp-select.vue";
import EdpTextarea from "@/components/e-form/edp-textarea.vue";
import EdpDatePicker from "@/components/e-form/edp-date-picker.vue";
import IssueWorkTimeLog from "@/components/issueWorkTimeLog.vue";
import dayjs from "dayjs";
import EdpCheckboxGroup from "@/components/e-form/edp-checkbox-group.vue";
import taskModal from "@/components/taskModal.vue";
import RelevancyWorkModal from "@/components/relevancyWorkModal.vue";

export default {
  mixins: [catMixin],
  emits: ['reload', 'render'],
  props: {
    oid: {
      type: String
    },
    pretasks: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      validator: val => ['project', 'supplier'].includes(val),
      default: 'project'
    }
  },
  data() {
    return {
      dbs: {
        prjIssue: new FieldUpdateDb("prj", "prj_issue")
      },
      cat: {
        issues_level: [],
        issue_bstatus: [],
        issue_tags: []
      },
      comment: "",
      hasDetail: false,
      detail: {},
      stageList: [],
      pre_schedule_start_date: ""
    }
  },
  watch: {
    oid: {
      immediate: true,
      handler(oid) {
        if (oid) {
          this.detail.oid = oid
          this.getDetail()
        } else {
          this.hasDetail = false
        }
      }
    },
  },
  computed: {
    issue_tags() {
      return this.cat.issue_tags.map(item => ({ label: item.cat_name, value: item.cat_code }))
    },
    issues_level_list() {
      return this.cat.issues_level.slice().reverse();
    },
    myIssue() {
      let oid = JSON.parse(localStorage.getItem("company_info")).account_oid
      return this.detail.issue_owner === oid || this.detail.creator_oid === oid
    },
    hasEditP() {
      if (this.myIssue) {
        return true
      }
      return this.$hasP("T_UPDATEALL")
    },
    hasDeleteP() {
      if (this.myIssue) {
        return true
      }
      return this.$hasP("T_DELALL")
    },
    inAssists() {
      let oid = JSON.parse(localStorage.getItem("company_info")).account_oid
      return !!this.detail.assists?.find(item => item.oid === oid)
    }
  },
  methods: {
    openRelevancyWorkModal(row) {
      this.$refs.relevancyWorkModal.prjoid = row.prjoid
      this.$refs.relevancyWorkModal.open(row)
    },
    openTask(row) {
      this.$refs.taskModal.open(row.oid)
    },
    deleteTask(row) {
      this.$confirm({
        title: `确定移除关联工作项${row.issue_name}?`,
        onOk: async () => {
          const result = this.detail.pretasks.split(",").filter(item => !row.issue_code.split(",").includes(item)).join(",");
          console.log(result)
          const res = await fetch("post", "/prj/update/onefield", {
            field_id: "prj_issue",
            field_name: "pretasks",
            field_value: result,
            oid: this.detail.oid,
            prjoid: this.detail.prjoid,
            old_value: this.detail.pretasks,
            new_value: result
          })
          if (res.status === 0) {
            this.$message.success("移除成功")
            this.$emit('reload')
            this.getDetail()
          } else {
            this.$message.error(res.message)
          }
        },
        onCancel() {
        },
      });
    },
    changeScheduleStartDate() {
      let val = this.detail.schedule_start_date
      let pre = this.pre_schedule_start_date
      let currentEnd = this.detail.schedule_end_date
      if (val) {
        let formatTemplate = "YYYY/MM/DD"
        if (currentEnd && pre) {
          let start = dayjs(pre, formatTemplate)
          let end = dayjs(currentEnd, formatTemplate)
          let duration = dayjs.duration(end.diff(start))
          currentEnd = dayjs(val, formatTemplate).add(duration.asDays(), "day").format(formatTemplate)
        } else {
          currentEnd = dayjs(val, formatTemplate).add(3, "day").format(formatTemplate)
        }
        this.$refs.schedule_end_date.setValue(currentEnd)
      }
    },
    recordStartDate(status) {
      if (status === true) {
        this.pre_schedule_start_date = this.detail.schedule_start_date
      }
    },
    reloadMoneyModalIssueList() {
      this.$refs.moneyLog?.reloadModalIssueList()
    },
    getStageList() {
      fetch("post", "/prj/phase/retrieve", { prjoid: this.detail.prjoid, page_size: 999 }).then(res => {
        if (res.status === 0) {
          this.$emit('render')
          this.stageList = res.data.datas.map(item => {
            return {
              label: item.phase_name,
              value: item.oid,
              key: item.oid
            }
          })
        }
      })
    },
    getDetail() {
      let url = window.mock ? "http://127.0.0.1:4523/m1/2234408-0-default/prj/issue/details?apifoxApiId=59770876" : "/prj/issue/details"
      return fetch("post", url, {
        oid: this.oid
      }).then(res => {
        if (res.status === 0 || window.mock) {
          if (this.position === 'project') {
            if (this.$getP(res.prjoid).length === 0) {
              this.$store.dispatch('getPrjPermissions', res.data.prjoid)
            }
          }
          this.hasDetail = true
          this.detail = {
            ...res.data,
            issue_tag: res.data.issue_tag ? res.data.issue_tag.split(',') : []
          }
          this.$nextTick(() => {
            this.$refs.moneyLog.getMoneyLogList()
          })
          this.getStageList()
          return Promise.resolve()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    sendComment() {
      fetch("post", "/prj/issuelog/create", {
        prjoid: this.detail.prjoid,
        issueoid: this.detail.oid,
        log_content: this.comment,
        log_type: "0"
      }).then(res => {
        if (res.status === 0) {
          this.comment = ""
          this.$message.success("评论成功")
          this.$refs.bizlog?.getBizLogList()
          this.$refs.commentList?.getCommentList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    deleteDetail() {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          return fetch("post", "/prj/issue/delete", { oid: this.detail.oid }).then(res => {
            if (res.status === 0) {
              this.$message.success("删除成功")
              this.$emit('reload')
              this.hasDetail = false
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {
        },
      });
    },
  },
  components: {
    RelevancyWorkModal,
    taskModal,
    EdpCheckboxGroup,
    EdpDatePicker,
    EdpTextarea,
    EdpSelect,
    EdpInput,
    IssueMoneyLog,
    CommentList,
    Bizlog,
    attachmentUpload,
    // eslint-disable-next-line vue/no-unused-components
    IssueWorkTimeLog
  },
}
</script>

<style lang="less" scoped>
// 右侧信息
.body-right-info {
  flex-shrink: 0;
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  border-top: 1px solid #ededed;
  background-color: #FFF;
  // 可以滚动的部分
  .info-scroll-content {
    padding-right: 20px;
    overflow-y: auto;
    padding-bottom: 10px;
    padding-left: 20px;

    // 头部
    .content-head {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .anticon {
        margin-right: 5px;
        font-size: 16px;
      }

      .content-head-id {
        display: flex;
        align-items: center;

        .anticon {
          color: #36A3FF;
        }

        > img {
          display: inline-block;
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin-right: 8px;
        }

        .id {
          color: #4D4D4D;
          font-size: 12px;
        }
      }

      .content-head-icons {
        margin-left: auto;
      }
    }

    // 标题
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      color: #4D4D4D;
    }

    // 两列的状态
    .scroll-content-status {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-row-gap: 24px;
      margin-top: 13px;
      margin-bottom: 20px;

      .edp-select, .edp-input {
        --width: 90%;
      }

      .status-item {
        display: flex;
        align-items: center;

        .round {
          //background-color: #D4D4D4;
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .status-item-right {
          width: calc(100% - 41px);
          flex: 1;
          line-height: 1;
          margin-left: 11px;

          .status-title {
            font-size: 16px;
            color: #4D4D4D;
            margin-bottom: 4px;
            font-weight: 500;

            /deep/ .ant-select-selection-selected-value {
              font-size: 16px;
            }

            /deep/ .ant-select-search__field__wrap {
              input {
                font-size: 16px;
                font-weight: 500;
              }
            }
          }

          .status-sub-text {
            color: #A7A7A7;
            font-size: 12px;
          }
        }
      }
    }

    // 描述输入框 后期会换成富文本编辑框
    .description {
      width: 100%;
      height: 150px;
      margin-top: 15px;
      //border: 1px solid #EDEDED;
    }

    // 文本信息展示
    .content-group-info {
      //padding-top: 20px;
      .head {
        display: flex;
        align-items: center;
        margin: 24px 0 15px;

        .text {
          font-weight: 500;
          font-size: 16px;
          color: #4d4d4d;
        }

        .right-action {
          display: flex;
          align-items: center;
          font-size: 14px;
          margin-left: auto;
          cursor: pointer;

          img {
            margin-right: 5px;
          }
        }
      }

      // 每组信息的标题
      .group-info-title {
        margin-top: 24px;
        font-size: 16px;
        color: #4D4D4D;
        margin-bottom: 15px;
      }

      .taps {
        margin-left: 10px;
        display: inline-block;
        font-size: 12px;
        color: #A7A7A7;
        border: 1px solid #EDEDED;
        line-height: 26px;
        padding: 0 14px;
        border-radius: 26px;
      }

      // 每组信息列表
      .group-info-list {
        > .title {
          font-size: 18px;
        }

        .info-item {
          display: flex;
          align-items: center;
          color: #4D4D4D;
          font-size: 16px;
          min-height: 32px;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          .info-key {
            font-size: 14px;
            max-width: 150px;
            font-weight: 400;
            width: 30%;
            min-width: 80px;
          }

          .info-value {
            font-size: 14px;
            color: #333;

            .synergies {
              /deep/ .ant-select-selection {
                height: auto;
              }
            }

            &.money {
              display: flex;
              align-items: center;

              > span {
                font-size: 14px;
                margin-right: 10px;
              }
            }

            /deep/ input {
              font-size: 14px;
            }
          }
        }

        .work-list {
          max-height: 150px;
          overflow-y: auto;
          margin-bottom: 20px;

          .work-item {
            display: flex;
            line-height: 36px;
            justify-content: space-between;
            border-bottom: 1px solid #EDEDED;;
            position: relative;

            .edp-status {
              font-size: 12px;
            }

            .text-block {
              margin-right: 10px;
              display: inline-block;
              font-size: 12px;
              color: #4D4D4D;

              &.red {
                color: #ED3535;
                background-color: #FDE6E6;
              }

              &.blue {
                color: var(--primary-color);
                background-color: #E0ECFB;
              }
            }

            .icon {
              display: inline-block;
              margin-right: 10px;
              width: 16px;
            }

            &:first-child {
            }

          }
        }

        //动态和评论
        /deep/ .container-tabs {
          height: 100%;

          .ant-tabs-nav .ant-tabs-tab {
            margin: 0;
          }

          .ant-tabs-bar {
            margin: 0;
          }

          .ant-tabs-content {
            height: 100%;
          }

          .tabs-content {
            height: 100%;

            .dynamic-items {
              padding: 13px;
              align-items: baseline;

              .info {
                padding-left: 14px;
                font-size: 12px;
                color: #909090;

                .name {
                  color: #000000;
                  margin-bottom: 9px;

                  > span {
                    color: #909090;
                  }
                }

                .detail {
                  .icon {
                    transform: rotate(-90deg);
                    color: #909090;
                  }

                  > div {
                    margin-right: 7px;
                  }

                  > div:nth-last-child(1) {
                    margin: 0;
                  }

                  .finish {
                    color: #24B47E;
                    background-color: #E9F7F2;
                  }

                  .unfinish {
                    color: #EA0D0D;
                    background: #FDE6E6;
                  }
                }
              }
            }

            .detail-title {
              padding-left: 16px;
              height: 55px;
              line-height: 55px;
              box-sizing: border-box;
              border-bottom: 1px solid #CACACA;
              font-size: 14px;
              color: #000000;
            }

            .detail-items {
              padding: 13px 41px;

              .detail-item-1 {
                margin-bottom: 14px;

                .top {
                  color: rgba(0, 0, 0, 1);
                  font-size: 12px;
                  margin-bottom: 9px;

                  > div {
                    margin-right: 5px;
                  }

                  > div:nth-last-child(1) {
                    margin-right: 0;
                    color: rgba(144, 144, 144, 1);
                  }

                  .status {
                    color: #24B47E;
                    background: #E9F7F2;
                    padding: 0 5px;
                  }

                  .status.nopass {
                    color: #EA0D0D;
                    background: #FDE6E6;
                  }
                }

                .bottom {
                  font-size: 12px;
                  color: #909090;
                  font-weight: 500;
                  align-items: baseline;
                  height: 100%;
                  position: relative;

                  > div {
                    margin-left: 14px;
                  }

                  > div:nth-last-child(1) {
                    width: 205px;
                    color: var(--primary-color);
                    font-weight: 500;
                  }

                  > div:nth-of-type(1) {
                    margin: 0;
                  }
                }
              }

              .detail-item-2 {
                font-size: 12px;
                color: #000000;
                margin-bottom: 14px;

                > button {
                  margin-right: 13px;
                }

                > button:nth-last-child(1) {
                  border: 1px solid var(--primary-color);
                  color: var(--primary-color);
                }
              }
            }

            .comment-items {
              padding: 13px;
              font-weight: 500;
              font-size: 12px;
              color: #000000;

              > img {
                width: 19px;
                height: 19px;
                vertical-align: middle;
              }

              .info {
                margin: 0 8px;

                > .name {
                }

                > .time {
                  font-weight: 400;
                  font-size: 12px;
                  color: #919191;
                }
              }

              .content {
                font-weight: 400;
                color: #919191;
              }
            }
          }
        }
      }

      .line {
        margin: 24px 0 0;
        width: 100%;
        height: 1px;
        background: #edededff;
      }

      .tag-record-list {
        .tag-record-item {
          padding: 12px;
          border-bottom: 1px solid #C2C9CF;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 12px;

          .name {
            flex: 1;
          }

          &:hover {
            background-color: #f9f9f9;
          }

          > * {
            margin-right: 8px;
          }

          .close {
            margin-right: 0;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            border-radius: 3px;

            &:hover {
              background-color: #e7e7e7;
            }
          }
        }
      }
    }
  }

  .filecontent {
    margin-bottom: 20px;

    .item {
      margin-bottom: 15px;
      border-bottom: 1px solid #C2C9CF;
      padding-bottom: 15px;

      .left {
        width: 100%;

        .file-icon {
          width: 32px;
          height: 32px;
          margin-right: 5px;

          > img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        .file-info {
          flex-grow: 1;
          font-weight: 500;
          font-size: 12px;
          color: #9B9B9B;

          .file-info-name {
            color: #303030;
            margin-bottom: 3px;
          }

          .file-info-size {
            margin-right: 25px;
          }
        }
      }

      .right {
        flex-shrink: 0;

        > .file-operation {
          cursor: pointer;
          margin-left: 11px;

          > i {
            font-size: 14px;
            color: #303030;
            font-weight: 700;
          }
        }

        > .file-operation:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }

    & > .item:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  // 右侧下方固定不滚动的部分
  .right-info-footer {
    border-top: 1px solid #eee;
    flex-shrink: 0;
    height: 96px;

    // 评论部分
    .input-group {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #EDEDED;

      .input {
        height: 48px;
        border: none;
        outline: none;
        flex: 1;
        padding-left: 20px;
      }

      .btn {
        cursor: pointer;
        width: 100px;
        text-align: center;

        .anticon {
          font-size: 20px;
          color: #333;
        }
      }
    }

    // 关注部分
    .follow {
      display: flex;
      height: 48px;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;

      .text {
        color: #4D4D4D;
        font-size: 16px;

        // 关注人数
        span {
          line-height: 16px;
          height: 16px;
          display: inline-block;
          background-color: #D9D9D9;
          border-radius: 4px 4px 4px 4px;
          font-size: 14px;
          border-radius: 4px;
          padding: 0 5px;
          margin: 0 5px;
        }
      }

      // 关注按钮
      .action {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 20px;

        .anticon {
          margin-right: 5px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>

<template>
  <a-modal v-model="visible" title="批量调整" class="batchChangeModal" width="680px">
    <a-spin :spinning="accountLoading || phaseLoading">
      <a-form-model layout="vertical" ref="form" :model="form" class="edp-form">
        <div class="line-2">
          <a-form-model-item>
            <span slot="label">项目</span>
            <a-select allowClear v-model="form.prjoid" @change="onChangePrj(true)">
              <a-select-option v-for="item in projectList" :key="item.oid" :value="item.oid">
                {{ item.project_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <span slot="label">阶段</span>
            <a-select allowClear :disabled="!form.prjoid" v-model="form.phase_oid">
              <a-select-option v-for="item in phaseList" :key="item.oid" :value="item.oid">
                {{ item.phase_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <span slot="label">负责人</span>
            <a-select allowClear showSearch optionFilterProp="children" v-model="form.issue_owner"
                      :disabled="!form.prjoid">
              <a-select-option v-for="item in accountList" :value="item.user_oid" :key="item.user_oid">
                {{ item.name }} {{ item.title ? '-' + item.title : '' }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <span slot="label">任务状态</span>
            <a-select allowClear v-model="form.issue_status">
              <a-select-option v-for="item in cat.issue_bstatus" :key="item.cat_code" :value="item.cat_code">
                {{ item.cat_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <span slot="label">任务进度</span>
            <a-input-number style="width: 100%;" :min="0" :max="100" v-model="form.complete_percent"></a-input-number>
          </a-form-model-item>
          <a-form-model-item>
            <span slot="label">计划开始</span>
            <a-date-picker format="YYYY/MM/DD" valueFormat="YYYY/MM/DD" v-model="form.schedule_start_date"
                           style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item>
            <span slot="label">计划结束</span>
            <a-date-picker format="YYYY/MM/DD" valueFormat="YYYY/MM/DD" v-model="form.schedule_end_date"
                           style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item>
            <span slot="label">实际开始</span>
            <a-date-picker format="YYYY/MM/DD" valueFormat="YYYY/MM/DD" v-model="form.actual_start_date"
                           style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item>
            <span slot="label">实际结束</span>
            <a-date-picker format="YYYY/MM/DD" valueFormat="YYYY/MM/DD" v-model="form.actual_end_date"
                           style="width: 100%" />
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-spin>
    <template #footer>
      <div class="modal-footer">
        <a-space>
          <a-button @click="visible = false">
            取消
          </a-button>
          <a-button :loading="submitLoading" type="primary" @click="submit">
            确定
          </a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { fetch } from "@/utils/request";
import catMixin from "@/utils/mixins/catMixin";

export default {
  name: "batchChangeModal",
  mixins: [catMixin],
  emits: ["reload"],
  data() {
    return {
      cat: {
        issue_bstatus: []
      },
      visible: false,
      projectList: [],
      phaseList: [],
      accountList: [],
      form: {
        oid: "",
        prjoid: "",
        phase_oid: "",
        issue_owner: "",
        issue_status: "",
        complete_percent: "",
        schedule_start_date: "",
        schedule_end_date: "",
        actual_start_date: "",
        actual_end_date: ''
      },
      backups: {},
      submitLoading: false,
      phaseLoading: false,
      accountLoading: false
    }
  },
  created() {
    this.backups = JSON.parse(JSON.stringify(this.form))

  },
  methods: {
    submit() {
      this.submitLoading = true
      fetch("post", "/prj/issue/mutimove", {
        ...this.form
      }).then(res => {
        if (res.status === 0) {
          this.$message.success("批量修改成功")
          this.$emit("reload")
          this.visible = false
        } else {
          this.$message.error(res.message)
        }
      }).finally(() => {
        this.submitLoading = false
      })
    },
    open(oid) {
      this.getProjectList()
      this.form = JSON.parse(JSON.stringify(this.backups))
      this.form.oid = oid
      this.visible = true
    },
    onChangePrj() {
      if (!this.form.prjoid) return;
      this.form.issue_owner = ""
      this.form.phase_oid = ""
      this.form.product_oid = ""
      // this.getDemand()
      this.getAccount()
      this.getPhaseList()
      // this.getProductList()
    },
    getProjectList() {
      fetch("post", "/prj/retrieve").then(res => {
        if (res.status === 0) {
          this.projectList = res.data.datas
        }
      })
    },
    getAccount() {
      this.accountLoading = true
      fetch("post", "/prj/userlist/list", { prjoid: this.form.prjoid }).then(res => {
        if (res.status === 0) {
          console.log("batchChangeModal", res)
          this.accountList = res.data
        }
      }).finally(() => this.accountLoading = false)
    },
    getPhaseList() {
      this.phaseLoading = true
      return fetch("post", "/prj/phase/retrieve", {
        page_size: 999,
        prjoid: this.form.prjoid
      }).then(res => {
        if (res.status === 0) {
          this.phaseList = res.data.datas
        }
      }).finally(() => this.phaseLoading = false)
    },
  }
}
</script>

<style lang="less" scoped>
.batchChangeModal {

}
</style>
